import { useState, useEffect } from 'react';

export default function Home() {
  const [popupData, setPopupData] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleButtonClick = (data) => {
    setPopupData(data);
    setShowVideo(false); 
  };

  const closePopup = () => {
    setPopupData(null);
    setShowVideo(false); 
  };

  const toggleVideo = () => {
    setShowVideo(!showVideo);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const paragraphContent = isMobile ? (
    <>
      Get ready, Canadian Contractors! ClearSky Software is LAUNCHING <strong>"Contractors Nail IT"</strong> – the platform that’s going to revolutionize your construction business. Be part of our Beta program & be rewarded with lifetime discounts.
    </>
  ) : (
    <>
      Get ready, Canadian Contractors! ClearSky Software is LAUNCHING <strong>"Contractors Nail IT"</strong> – the platform that’s going to revolutionize your construction business. We are selecting no more than 50 Beta Trailblazers from across Canada to be part of our initial rollout. Be one of the first to experience the future of construction tech! Your feedback will shape the platform, and in return, we're rewarding Beta Trailblazers with incredible discounts and extended services.
    </>
  );



  const data = [
    { 
      title: 'AI - 3D Design', 
      image: './box/ImageTwelve.png', 
      briefDescription: 'Visualize & redesign your home with ease using AI.', 
      detailedDescription: 'Our cutting-edge AI Image Generator empowers customers to visualize their dream home transformations effortlessly. By simply uploading an image, users can explore and apply various design elements to their property, creating personalized and precise redesigns. Taking your initial designs one step further, we connect users to our 3D designers. At this step users will be able to step into their virtual designs allowing them to fully experience the changes .', 
      videoUrl: 'https://www.youtube.com/embed/O9U8f6BBjvk'
    },
    { 
      title: 'Lead Text', 
      image: './box/ImageEleven.png', 
      briefDescription: 'Boost leads & conversions with tailored texts.', 
      detailedDescription: 'We all know that we live in a world where everyone wants answers immediately. Our Lead Text feature on your website connects to our SMS gateway, sending you the name and mobile # so that you can speak to your potential customers as quickly as possible. This one feature will dramatically enhance conversions. By automating and personalizing your outreach, Lead Text helps you nurture prospects, drive conversions, and enhance your overall lead generation strategy. ', 
      imageUrl: './images/comsoon.jpg'
    },
      { title: 'AI Agent', image: './box/ImageTen.png', briefDescription: 'Meet Molly: Your AI for 24/7 business support.', detailedDescription: 'Introducing Molly Hammer, your dedicated AI    assistant designed to revolutionize your business operations. Molly provides 24/7 customer support, swiftly handling inquiries, scheduling, and lead generation to ensure seamless interactions. With her ability to offer personalized responses and streamline tasks, Molly enhances client satisfaction and boosts efficiency, allowing your team to focus on what matters most. From automating routine processes to delivering targeted marketing insights, Molly is here to drive your business success.'
      ,videoUrl: 'https://www.youtube.com/embed/1H2q_K0dIiQ' },

    { title: 'Social Media', image: './box/ImageNine.png', briefDescription: 'Elevate your brand & leads with social media plans', detailedDescription: 'Social media is essential for contractors looking to expand their reach and grow their customer base. At ClearSky Software, we leverage platforms like Facebook, Instagram, Google My Business, and LinkedIn to build your brand, foster trust, and drive sales. Through strategic content sharing,  and engaging with your audience, our approach helps increase visibility, generate qualified leads, and enhance your online presence. '
    ,videoUrl: 'https://www.youtube.com/embed/XylpPeh5q9E' },

    { title: 'Demo Room', image: './box/ImageEight.png', briefDescription: 'Explore, interact, and engage seamlessly in our Demo Room.', detailedDescription: 'Our Demo Room is a dynamic virtual space where your users can explore topics of interest, invite others, and interact with our team. Each topic has a unique link for easy access, allowing you to switch between them effortlessly. You can also invite colleagues, request live support, and book appointments—all within the Demo Room. With features like note-taking, live Q&A, and downloadable summaries, it’s designed to be intuitive and user-friendly, making it simple to engage and gather the information you need.'
    ,videoUrl: 'https://www.youtube.com/embed/ArgvO6hOYVU'},

    { title: 'Adaptive Design', image: './box/ImageSeven.png', briefDescription: 'Adaptive design fits all devices for optimal use.', detailedDescription: 'Adaptive Design ensures your website provides a seamless experience across all devices. By automatically adjusting layouts and content to fit different screen sizes and resolutions, this tool optimizes user interaction and engagement. Whether on a smartphone, tablet, or desktop, Adaptive Design guarantees that your site looks and performs beautifully, helping you reach and captivate your audience effectively.',imageUrl: './images/comsoon.jpg' },

    { title: 'Block Architecture', image: './box/ImageSix.png', briefDescription: 'Modular block design allows easy website changes.', detailedDescription: 'Experience the ease and flexibility of Block Architecture. This innovative approach uses modular blocks to build and customize your site. Each block represents a section, allowing for seamless and intuitive design changes. With Block Architecture, you can effortlessly create, modify, and manage your website layout, ensuring a responsive and visually appealing site.'
    ,videoUrl: 'https://www.youtube.com/embed/sQ-1sEI3x9A' },

    { title: 'Project Management App', image: './box/ImageFive.png', briefDescription: 'Streamline tasks, schedules, and teamwork with our PMA.', detailedDescription: 'Enhance your project efficiency with our Project Management App. Designed to organize tasks, manage timelines, and collaborate with your team, this app provides a central hub for all your project needs. Track progress, assign responsibilities, and meet deadlines with ease, ensuring that your projects stay on track and your team remains align'
    ,imageUrl: './images/comsoon.jpg' },

    { title: 'Document Management System', image: './box/ImageFour.png', briefDescription: 'Organize, track, and manage documents effortlessly.', detailedDescription: 'Our Document Management System (DMS) is designed to keep your files organized and accessible. Easily manage contracts, invoices, and permits without sifting through paper or endless folders. With features like automated approvals, document tracking, and seamless email integration, our DMS ensures you stay on top of your paperwork. It saves you time, reduces risk, and enhances collaboration, helping you stay organized and professional on every project.'
    ,videoUrl: 'https://www.youtube.com/embed/2Bn7WZuGMpI' },

    { title: 'Advanced Marketing', image: './box/ImageThree.png', briefDescription: 'Use geofencing and tech to enhance local business.', detailedDescription: 'Innovated Strategies that combine technology and marketing an example that we will introduce is geofencing and how it is applied for the benefit of local businesses '
    ,imageUrl: './images/comsoon.jpg' },

    { title: 'Business Intelligence', image: './box/ImageTwo.png', briefDescription: 'Unlock growth with data: analyze and refine strategies.', detailedDescription: 'Discover how our analytics tool can transform your business with powerful insights. Analytics helps you understand local customer behavior by revealing search keywords, tracking page visits, and identifying device usage. With location data and engagement metrics, you can tailor your content and marketing strategies to better connect with your community. Leverage these insights to optimize your website, enhance user experience, and make data-driven decisions that drive growth and success.'
    ,videoUrl: 'https://www.youtube.com/embed/2KYi0gDTOtI' },
    
    { title: 'Local SEO', image: './box/ImageOne.png', briefDescription: 'Enhance local visibility with targeted SEO.', detailedDescription: 'Unlock the power of Local SEO to connect with customers right in your neighborhood. By optimizing your online presence with geographically relevant keywords and improving your visibility on Google Maps, our Local SEO tool helps your business stand out. From enhancing your Google Business Profile to building local backlinks, our approach ensures you capture valuable leads and drive growth in your community.'
    ,videoUrl: 'https://www.youtube.com/embed/hP0y1C0NZek'
  },
  
  ];

  return (
    <>
       {/* Hero Section */}
       <div className='hero'>
        <div className='heroleft'>
          <h4><br/><strong className='nailit'>Contractors Nail IT</strong></h4>
          <h3>How to grow your opportunities by <br/> <strong className='forsix'> 40-60%? </strong>You need the right tools.</h3>
          <p className='parahero'>{paragraphContent}</p>
          <div className="custom-divider"></div>
          <a href="https://calendly.com/r-dredhartclearskymarketing/contractors-nail-it?month=2024-09">
            <button className='btnhero1'>Let's have a Conversation</button>
          </a>
        </div>
        <div className='heroright'>
          <img className='heroimg' src='./images/RightHeroimg.png' alt='Hero' />
        </div>
      </div>

      <div className="promo">
       <div className='pro1'>
          <h3>Contractors</h3>
          <h5>GET $2400 from the Canada Digital Adoption Plan to pay for this platform.</h5>
       </div>
       <div className='pro2'>
        <p>APPLICATIONS CLOSE Sept 30, 2024</p>
        <a href="https://drive.google.com/file/d/1RspiQhl8AUB-PQujWeq8xMaz-gf21c9_/view?usp=sharing">
            <button>Claim your $2400 Grant!</button>
          </a>
       </div>
       <div className='pro3'>
          <img src='/images/dont.png'></img>
       </div>
       <div className='pro4'>
        <img src='/images/banflag.jpg'></img>
       </div>
      </div>

      {/* Toolbox Section */}
      <div className='toolbox'>
        <div className='toolboxtitle'>
          <h4>Your Essential Toolbox for Success</h4>
          <p>Tools Designed to Drive Innovation, Efficiency, and Business Growth</p>
        </div>

        {[...Array(3)].map((_, i) => (
          <div key={i} className={`toolcard${i + 1}`}>
            {data.slice(i * 4, i * 4 + 4).map((item, index) => (
              <div key={index} className='card'>
                <div className='card-image-container'>
                  <img className='card-image' src={item.image} alt={item.title} />
                </div>
                <h3 className='card-title'>{item.title}</h3>
                <p className='card-description'>{item.briefDescription}</p>
                <button className='card-button' onClick={() => handleButtonClick(item)}>Learn More</button>
              </div>
            ))}
          </div>
        ))}
      </div>


       {/* Popup */}
        {popupData && (
          <div className='popup'>
            <div className='popup-content'>
              <span className='popup-close' onClick={closePopup}>&times;</span>
              {popupData.videoUrl ? (
                <iframe
                  className='popup-iframe'
                  src={popupData.videoUrl}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="YouTube video"
                  onClick={() => {
                    if (window.innerWidth <= 576) {
                      window.location.href = popupData.videoUrl;
                    }
                  }}
                ></iframe>
              ) : (
                <>
                  <img className='popup-image' src={popupData.imageUrl || popupData.image} alt={popupData.title} />
                  <h3 className='popup-title'>{popupData.title}</h3>
                  <p className='popup-description'>{popupData.detailedDescription}</p>
                </>
              )}
              <a href="https://calendly.com/r-dredhartclearskymarketing/overview-of-construction-beta-program?month=2024-09">
                <button className='btnhero1'>Learn More</button>
              </a>
            </div>
          </div>
        )}






      <div class="contain">
        <h1>What do I do next?</h1>

        <div class="step">
            <h2>Step 1 <span>Is my Business Eligible for the $2400 grant?</span></h2>
            <p>There are a number of conditions that a Canadian business will need in order to qualify. Call us at <a href="tel:(807)695-5372">(807) 695-5372</a>, we will walk you through the eligibility process.</p>
        </div>

        <div class="step">
            <h2>Step 2 <span>Initial Consultation</span></h2>
            <p>We will have a conversation that focuses on your business challenges and needs and will produce a strategic document. The document produced will highlight the digital components that you will need to integrate in order to be accepted within the Canada Digital Adoption Plan.</p>
        </div>

        <div class="step">
            <h2>Step 3 <span>Complete the Paperwork</span></h2>
            <p>Our Team will help you complete the paperwork and documents necessary to successfully file for the $2400 grant.</p>
        </div>

        <div class="info">
            <h2>Your business has Qualified</h2>
            <p>Invoicing will begin when you know that you have been accepted for the grant and programming work begins.</p>
        </div>

        <div class="info">
            <h2>We Understand</h2>
            <p>As a small business, you do not want to incur a charge when you are not successful in the application process. Therefore, steps 1, 2, and 3 will not cost you anything.</p>
        </div>

        {/* <a className='acta' href="https://calendly.com/r-dredhartclearskymarketing/overview-of-construction-beta-program?month=2024-09">
                <button className='cta-button'>Great opportunity Call now</button>
              </a> */}
          <p className='pcta'>For more information on Contractors Nail It Beta Trailblazers  - <span className='ctaspan'>Call us</span>  or Click  <span className='ctaspan'>Book Appointment</span>  </p>
    </div>
    </>
  );
}
