import React from "react"
import { Link } from 'react-router-dom';
import './MyFooter.css';


export default function MyFooter(){
    return(
    <>
      <div id="footer" className="foot">
        <div className="contft">
        <img className="ftlogo" src="images/cleaar.jpg" height={100}></img>
        </div>
        <div className="contac">
          <p>Email: r.dredhart@clearskysoftware.net</p>
          <p>Phone: (807) 695-5372</p>
          <p>Cell:  (705)-274-9564</p>
        </div>
        <div className="btnfoot">
          <a href="https://calendly.com/r-dredhartclearskymarketing/overview-of-cdap-and-contractors-nail-it?month=2024-09"> 
          <button className="btnft">Book Appointment</button></a>
        </div>
      </div>
    </>
    )
}